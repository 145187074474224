export const tableCol = [
  {
    prop: 'merge',
    label: '展示图/原型名称',
    slotName: 'mergeSlot',
    minWidth: 200
  },
  {
    label: '原型分类',
    slotName: 'CategorySlot',
    minWidth: 150
  },
  {
    prop: 'styleList',
    label: '款式',
    slotName: 'structSlot',
    minWidth: 150
  },
  {
    prop: 'sizeList',
    label: '尺码',
    minWidth: 150,
    slotName: 'sizesSlot'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot',
    minWidth: 120
  }
]
